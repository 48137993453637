<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">

            <!-- Register v1 -->
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <!--          <vuexy-logo />-->
                    <h2 class="brand-text text-primary ml-1">
                        MikroBilling
                    </h2>
                </b-link>

                <b-card-title class="mb-1 text-center">
                    Join our MikroBilling Network 🚀
                </b-card-title>
                <b-card-text class="mb-2 text-center">
                    Give Relax to your Users!
                </b-card-text>

                <!-- form -->
                <validation-observer
                    ref="registerForm"
                    #default="{invalid}"
                >
                    <b-form
                        class="auth-register-form mt-2"
                        @submit.prevent="register"
                    >
                        <!-- username -->
                        <b-form-group
                            label="Person Name"
                            label-for="register-username"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Username"
                                rules="required"
                                vid="username"
                            >
                                <b-form-input
                                    id="register-username"
                                    v-model="username"
                                    :state="errors.length > 0 ? false:null"
                                    name="register-username"
                                    placeholder="Type Person Name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <!-- Mobile Number -->
                        <b-form-group
                            label="Mobile Number"
                            label-for="register-mobile"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Mobile"
                                rules="required"
                                vid="mobile"
                            >
                                <VuePhoneNumberInput id="mobile" v-model="mobile" clearable default-country-code="BD"
                                                     @update="onUpdate"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <!-- email -->
                        <b-form-group
                            label="Email"
                            label-for="register-email"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Email"
                                rules="required|email"
                                vid="email"
                            >
                                <b-form-input
                                    id="register-email"
                                    v-model="userEmail"
                                    :state="errors.length > 0 ? false:null"
                                    name="register-email"
                                    placeholder="john@example.com"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <!-- password -->
                        <b-form-group
                            label="Password"
                            label-for="register-password"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Password"
                                rules="required"
                                vid="password"
                            >
                                <b-input-group
                                    :class="errors.length > 0 ? 'is-invalid':null"
                                    class="input-group-merge"
                                >
                                    <b-form-input
                                        id="register-password"
                                        v-model="password"
                                        :state="errors.length > 0 ? false:null"
                                        :type="passwordFieldType"
                                        class="form-control-merge"
                                        name="register-password"
                                        placeholder="············"
                                    />
                                    <b-input-group-append is-text>
                                        <feather-icon
                                            :icon="passwordToggleIcon"
                                            class="cursor-pointer"
                                            @click="togglePasswordVisibility"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <!-- confirm password -->
                        <b-form-group
                            label="Confirm Password"
                            label-for="reset-password-confirm"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Confirm Password"
                                rules="required|confirmed:password"
                            >
                                <b-input-group
                                    :class="errors.length > 0 ? 'is-invalid':null"
                                    class="input-group-merge"
                                >
                                    <b-form-input
                                        id="reset-password-confirm"
                                        v-model="cPassword"
                                        :state="errors.length > 0 ? false:null"
                                        :type="password2FieldType"
                                        class="form-control-merge"
                                        name="reset-password-confirm"
                                        placeholder="············"
                                    />
                                    <b-input-group-append is-text>
                                        <feather-icon
                                            :icon="password2ToggleIcon"
                                            class="cursor-pointer"
                                            @click="togglePassword2Visibility"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-button
                            :disabled="invalid"
                            block
                            type="submit"
                            variant="primary"
                        >
                            Sign up
                        </b-button>
                    </b-form>
                </validation-observer>

                <p class="text-center mt-2">
                    <span>Already have an account?</span>
                    <b-link :to="{name:'auth-login'}">
                        <span>&nbsp;Sign in instead</span>
                    </b-link>
                </p>

            </b-card>
            <!-- /Register v1 -->
        </div>
    </div>

</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
    BCard, BLink, BCardTitle, BCardText, BForm,
    BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox, Alert, BAlert
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {required, email} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import useJwt from "@/auth/jwt/useJwt";
import {checkRes} from "@core/services/helper";

export default {
    components: {
        BAlert,
        VuePhoneNumberInput,
        VuexyLogo,
        // BSV
        BCard,
        BLink,
        BCardTitle,
        BCardText,
        BForm,
        BButton,
        BFormInput,
        BFormGroup,
        BInputGroup,
        BInputGroupAppend,
        BFormCheckbox,
        // validations
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            status: '',
            username: '',
            userEmail: '',
            password: '',
            cPassword: '',
            mobile: '',
            sideImg: require('@/assets/images/pages/register-v2.svg'),
            // validation,
            required,
            email,
            // Toggle Password
            password1FieldType: 'password',
            password2FieldType: 'password',
            countryCallingCode: null,
            countryCode: null,
            phoneNumber: null,
            isValidMobile: null,
            validationdata: []
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        password2ToggleIcon() {
            return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    methods: {
        onUpdate(payload) {
            this.countryCallingCode = payload.countryCallingCode
            this.countryCode = payload.countryCode
            this.phoneNumber = payload.nationalNumber
            this.isValidMobile = payload.isValid
        },
        register() {
            this.$refs.registerForm.validate().then(success => {
                if (success && this.isValidMobile) {
                    useJwt.register({
                        name: this.username,
                        email: this.userEmail,
                        mobile: this.countryCallingCode + this.phoneNumber ?? this.mobile,
                        countryCallingCode: this.countryCallingCode,
                        countryCode: this.countryCode,
                        password: this.password,
                        password_confirmation: this.cPassword,
                    })
                        .then(response => {
                            console.log(response.data.messages)
                            // @ts-ignore
                            if (checkRes(response.status) && response.data.data) {
                                console.log(true)
                                useJwt.setToken(response.data.data.accessToken)
                                useJwt.setRefreshToken(response.data.data.accessToken)
                                localStorage.setItem('userData', JSON.stringify(response.data.data.user))
                                this.$ability.update(response.data.data.user)
                                this.$router.push('/')
                            } else {

                                this.validationdata = response.data.messages[0];
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: `ISP Register`,
                                        icon: 'CoffeeIcon',
                                        variant: 'warning',
                                        text: this.validationdata,
                                    },
                                })
                                this.$router.push({name: "auth-register"})
                            }
                        })
                        .catch(error => {
                            console.log(error)
                            this.$refs.registerForm.setErrors(error.response.data.error)
                        })
                }
            })
        },
        togglePassword1Visibility() {
            this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
        },
        togglePassword2Visibility() {
            this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
